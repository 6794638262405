import 'bootstrap';
import 'slick-carousel';

$('.products-carousel').slick({
	rtl: true,
	rows: 0,
	arrows: true,
	prevArrow: '<button type="button" class="slick-prev"></button>',
	nextArrow: '<button type="button" class="slick-next"></button>',
	dots: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	infinite: false,
	responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
			}
		}
	]
})
